
import IconStyle from "~/models/general/IconStyle"
import { getBorder } from "~/models/general/BorderModel"

import { formatButtonStyle } from "~/helpers/button/button"
import { formatIconStyle } from "~/helpers/icon/icon"

import { formatMargin, formatPadding } from "../style"
import { formatFontStyle } from "../style/font"
import { CardModel } from '../components/content/CardStyle'

class TabData {
  constructor() {
    this.id = 1
    this.name = null
    this.contentId = null
    this.type = "ContentGrid"
    this.layout = null
    this.data = null
    this.active = 0
    this.finished = false
    this.props = {}
    this.linkData = null
    this.components = {
      button: {},
      loadMoreButton: {},
    }
  }

  fromData(data) {
    this.id = 1
    this.type = data.type
    this.data = data.data
  }

  setTabManagement(tabsIndex, tabIndex) {
    if (tabsIndex === 0 && tabIndex === 0) {
      this.active = 0
    }
  }

  setTabName(data) {
    this.name = data
  }

  setTabLinkData(data, canChangeOnHover) {
    if (canChangeOnHover)
      this.linkData = data
  }

  // setTabLinkData(data, canChangeOnHover) {
  //   if (!data) return

  //   this.linkData = data

  //   if (canChangeOnHover) {
  //     this.linkData.active = true
  //   } else {
  //     this.linkData.active = false

  //   }
  // }

  setTabContentId(data) {
    this.contentId = data
  }

  setTabLayout(tabLayoutData) {
    //TODO set type from layout
    // const [singleLayoutData] = data

    if (!tabLayoutData) return


    this.type = tabLayoutData.type

    if (tabLayoutData.type === 'image') {
      // this.layout = 'meni'
      this.props = tabLayoutData.props
      this.style = tabLayoutData.style
      return
    }


    this.id = tabLayoutData.id
    // this.layout = tabLayoutData
    this.props.groupDescendantId = tabLayoutData.props.groupDescendantId
    this.props.infiniteScroll = tabLayoutData.props.infiniteScroll

    this.props.columnsNumber = tabLayoutData.props.columnsNumber
    this.props.queryId = tabLayoutData.props?.query?.id
    this.selectionType = tabLayoutData.props?.selectionType

    this.props.mediaNumber = tabLayoutData.props.mediaNumber ? tabLayoutData.props.mediaNumber : 1
    this.props.sortingMethod = tabLayoutData.props?.sortingMethod
    this.props.sortingMethods = tabLayoutData.props?.sortingMethods
    this.props.contentId = tabLayoutData.props?.contentId
    this.props.title = tabLayoutData.props?.title
    this.titleStyle = formatFontStyle(tabLayoutData?.style?.titleStyle)
    this.props.button = tabLayoutData?.props?.showButton
    this.props.hasLoadMoreButton = tabLayoutData?.props?.hasLoadMoreButton
    this.props.loadMoreRowCount =tabLayoutData?.props?.loadMoreRowCount

    this.cardId = tabLayoutData.cardId
    this.cardStyle = {}

    const s = new WidgetStyleModel()
    s.fromQuery(tabLayoutData.style)
    this.style = s

    if (tabLayoutData?.components && tabLayoutData?.components?.button) {
      this.components.button = tabLayoutData.components.button

      // const { button } = tabLayoutData.components;
      // this.components.button = {
      //   ...button,
      //   style: formatButtonStyle(button.style),
      //   icon: formatIconStyle(button.icon),
      // }
    }

    if (tabLayoutData?.components && tabLayoutData?.components?.loadMoreButton) {
      const { loadMoreButton } = tabLayoutData?.components
      this.components.loadMoreButton = {
        ...loadMoreButton,
        icon: formatIconStyle(loadMoreButton.icon),
        style: formatButtonStyle(loadMoreButton.style),
      }
    }

    if (tabLayoutData?.icon) {
      const generalStyle = new IconStyle()

      generalStyle.fromData(tabLayoutData?.icon)
      this.generalStyle = generalStyle
    } else if (tabLayoutData?.style?.generalStyle?.iconSettings) {

      this.generalStyle = { style: { iconSettings: tabLayoutData?.style?.generalStyle.iconSettings.style } }
    }


    if (tabLayoutData.type === 'banner') {
      // this.layout = 'meni'
      this.props = tabLayoutData.props
      this.style = tabLayoutData.style
    }

    if (tabLayoutData.type === 'ContentCarousel') {
      this.style.blurColor = tabLayoutData.style?.blurColor
    }

    if (tabLayoutData.props.card) {
      Object.entries(tabLayoutData.props.card.cards).forEach(([key, value]) => {
        const style = new CardModel()
        style.fromQuery(value)
        Object.assign(this.cardStyle, { [key]: style })
      })
    }

  }
}

class WidgetStyleModel {
  constructor() {
    this.padding = null
    this.gap = null
    this.margin = null
    this.borderProperties = null
  }

  fromQuery({ padding = null, spacing = null, margin = null, borderProperties = null }) {
    if (spacing) this.gap = `${spacing}px`
    if (padding) {
      const { padding: p } = formatPadding(padding)
      this.padding = p
    }
    if (margin) {
      const { margin: m } = formatMargin(margin)
      this.margin = m
    }
    if (borderProperties) {
      const { borderRadius, borderColor, borderStyle, borderWidth } = getBorder(borderProperties)
      this.borderRadius = borderRadius
      this.borderColor = borderColor
      this.borderStyle = borderStyle || 'solid'
      this.borderWidth = borderWidth
    }
  }
}

export default TabData